import React from "react";
import { useTracksFeed } from "./hooks";
import Player from "../../components/Player";

export default function Dashboard() {
  const { tracks: tracks, loading: loading, refetch: refetchMyTracks } = useTracksFeed();

  return (
    <div className="">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {tracks === null ? (
            <div className="text-gray-500">No tracks uploaded yet</div>
          ) : (
            tracks.map((track) => {
              return (
                <div key={track.id} className="py-4">
                  <Player track={track} />
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
}
