import React, { useContext, useState, useEffect, ReactNode, useCallback } from "react";
import { apiService } from "../../api/apiService";
import UserContext from "../../contexts/UserContext";
import type { UserProfile } from "../../api/apiService";

const REFRESH_THRESHOLD = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
const TOKEN_CHECK_INTERVAL = 24 * 60 * 60 * 1000; // Check token every 24 hours

export function UserProvider({ children }: { children: ReactNode }) {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verifyToken, setVerifyToken] = useState<string | null>(null);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    setUserProfile(null);
    setSession(false);
  }, []);

  const checkAndRefreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return;

    try {
      const tokenData = JSON.parse(atob(refreshToken.split(".")[1]));
      const expirationTime = tokenData.exp * 1000;
      const timeUntilExpiry = expirationTime - Date.now();

      if (timeUntilExpiry <= REFRESH_THRESHOLD) {
        const response = await apiService.refreshAccessToken(refreshToken);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
      }
    } catch (err) {
      console.error("Token refresh failed:", err);
      logout();
    }
  }, [logout]);

  useEffect(() => {
    const refreshInterval = setInterval(checkAndRefreshToken, TOKEN_CHECK_INTERVAL);
    return () => clearInterval(refreshInterval);
  }, [checkAndRefreshToken]);

  const checkAuth = useCallback(async () => {
    // eslint-disable-next-line no-debugger
    // debugger;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      await checkAndRefreshToken();

      setSession(true);
      const response = await apiService.getAuthenticatedUser();
      setUserProfile(response.data);
    } catch (err) {
      console.error("Error checking auth:", err);
      localStorage.removeItem("token");
    } finally {
      setLoading(false);
    }
  }, [checkAndRefreshToken]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const setAuthTokens = (token: string, refreshToken: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refreshToken);
    setSession(true);
    setLoading(false);
  };

  return (
    <UserContext.Provider
      value={{
        userProfile,
        setUserProfile,
        logout,
        setAuthTokens,
        session,
        loading,
        verifyToken,
        setVerifyToken,
        checkAuth,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a UserProvider");
  }
  return context;
}
