import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { apiService } from "../api/apiService";
import { useAuth } from "../providers/UserProvider";

export default function VerifyPage() {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { setVerifyToken } = useAuth();

  const resendVerification = async () => {
    setError(null);
    try {
      setLoading(true);

      const email = localStorage.getItem("signup_email");
      if (!email) {
        setError("No email found, please sign up again");
        return;
      }

      await apiService.signup(email);

      setSuccess(true);
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const errorData = err.response?.data;
        setError(errorData?.message || "An error occurred");
      } else {
        setError("Failed to resend verification email");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      setVerifyToken(token);
      navigate("/create-account");
    }
  }, [token, navigate, setVerifyToken]);

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-light">Verify Your Email</h2>
          <p className="mt-2 text-center text-gray-600 dark:text-gray-400">
            Please check your email for a verification link. If you haven&apos;t received it, you can request a new one.
          </p>
        </div>

        {error && (
          <div className="bg-red-50 p-4">
            <div className="text-sm text-red-700">{error}</div>
          </div>
        )}

        {success && (
          <div className="bg-green-50 p-4">
            <div className="text-sm text-green-700">Verification email sent! Please check your inbox.</div>
          </div>
        )}

        <div className="flex flex-col items-center">
          <button onClick={resendVerification} disabled={loading} className="btn-primary w-full max-w-xs">
            {loading ? "Sending..." : "Resend Verification Email"}
          </button>

          <button
            onClick={() => navigate("/")}
            className="mt-4 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
}
