import React, { useState } from "react";
import { useAuth } from "../../providers/UserProvider";
import { Link } from "react-router-dom";
import { apiService } from "../../api/apiService";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

interface LoginFormData {
  email: string;
  password: string;
}

export default function Login() {
  const { setAuthTokens, setUserProfile } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      setLoading(true);
      const response = await apiService.login(formData.email, formData.password);

      setAuthTokens(response.data.access_token, response.data.refresh_token);

      const { data: userProfile } = await apiService.getAuthenticatedUser();
      setUserProfile(userProfile);
      navigate("/dashboard");
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data.message || "Failed to login");
      } else {
        setError("Failed to login");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-light">Sign in to your account</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className=" bg-red-50 p-4">
              <div className="text-sm text-red-700">{error}</div>
            </div>
          )}
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="text-black dark:text-white block mb-2 font-base">
                Email
              </label>
              <input
                name="email"
                type="email"
                required
                className="input "
                placeholder="Email address"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="text-black dark:text-white block mb-2">
                Password
              </label>
              <input
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-black dark:border-white dark:bg-black placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <button type="submit" disabled={loading} className="button">
              {loading ? "Signing in..." : "Sign in"}
            </button>
          </div>

          <div className="text-center mt-4">
            <Link to="/forgot-password" className="text-black dark:text-white hover:opacity-80">
              Forgot password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
