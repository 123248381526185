import axios, { AxiosResponse, AxiosError } from "axios";
import { useAuth } from "../providers/UserProvider";

// Add at the top of the file with other imports
declare const localStorage: Storage;

// Create axios instance with default config
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8080", // adjust this URL as needed
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request interceptor (optional)
apiClient.interceptors.request.use(
  (config) => {
    // You can add auth tokens here

    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Add response interceptor (optional)
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // Handle errors globally
    if (error.response?.status === 401) {
      const { checkAuth } = useAuth();
      checkAuth();
      // localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default apiClient;
