import React, { useEffect, useState } from "react";
import Dropzone, { DropzoneRootProps, DropzoneInputProps } from "react-dropzone";
import { apiService } from "../../api/apiService";
import { useFlash } from "../../providers/FlashProvider";
import Select, { MultiValue } from "react-select";
import { useTags } from "./hooks";
import { Link } from "react-router-dom";

export default function Uploader() {
  const [isUploading, setIsUploading] = useState(false);
  const [trackData, setTrackData] = useState<{ id: number; name: string } | null>(null);
  const { tags, isLoading: tagsLoading } = useTags();

  const { showFlash } = useFlash();
  const randomHex = `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;

  const [selectedGenreTags, setSelectedGenreTags] = useState<MultiValue<{ value: number; label: string }>>([]);
  const [selectedStatusTags, setSelectedStatusTags] = useState<MultiValue<{ value: number; label: string }>>([]);

  const [genreTags, setGenreTags] = useState<MultiValue<{ value: number; label: string }>>([]);
  const [statusTags, setStatusTags] = useState<MultiValue<{ value: number; label: string }>>([]);
  useEffect(() => {
    const genreTagsOriginal = tags
      .filter((tag) => tag.category === "genre")
      .map((tag) => ({ label: tag.name, value: tag.id }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const statusTagsOriginal = tags
      .filter((tag) => tag.category === "status")
      .map((tag) => ({ label: tag.name, value: tag.id }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setGenreTags(genreTagsOriginal);
    setStatusTags(statusTagsOriginal);
  }, [tags]);

  useEffect(() => {
    // rerender
  }, [selectedGenreTags, genreTags]);

  type AddRemoveTagProps = {
    trackId: number;
    tag: { value: number; label: string };
    tags: MultiValue<{ value: number; label: string }>;
    selectedTags: MultiValue<{ value: number; label: string }>;
    setSelectedTags: (tags: MultiValue<{ value: number; label: string }>) => void;
    setTags: (tags: MultiValue<{ value: number; label: string }>) => void;
  };

  async function removeTag({ trackId, tag, tags, selectedTags, setSelectedTags, setTags }: AddRemoveTagProps) {
    await apiService.trackRemoveTag(trackId, tag.value);
    setSelectedTags(selectedTags.filter((t) => t.value !== tag.value));
    const updatedTags = [...tags, tag].sort((a, b) => a.label.localeCompare(b.label));
    setTags(updatedTags);
  }

  async function addTag({ trackId, tag, tags, selectedTags, setSelectedTags, setTags }: AddRemoveTagProps) {
    await apiService.trackAddTag(trackId, tag.value);
    setSelectedTags([tag, ...selectedTags]);
    const updatedTags = tags.filter((t) => t.value !== tag.value).sort((a, b) => a.label.localeCompare(b.label));
    setTags(updatedTags);
  }

  return (
    <div>
      <div className="mb-8">
        {!trackData && (
          <input
            type="file"
            accept="audio/*"
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (!file) return;

              const formData = new FormData();
              formData.append("file", file);
              formData.append("name", file.name);
              formData.append("color", "#" + Math.floor(Math.random() * 16777215).toString(16)); // Random hex color

              try {
                setIsUploading(true);
                const response = await apiService.trackCreate(formData);
                setTrackData(response.data);
              } catch (err) {
                console.error("Failed to upload track:", err);
              } finally {
                setIsUploading(false);
              }
            }}
            className="block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:border-0
            file:text-sm file:font-semibold
            file:bg-black file:text-white
            dark:file:bg-blue-900 dark:file:text-blue-100
            dark:hover:file:bg-blue-800"
          />
        )}

        {isUploading && (
          <div className="mt-4 flex items-center text-sm text-gray-500">
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Processing track...
          </div>
        )}
      </div>

      {trackData && !tagsLoading && (
        <div>
          <h2 className="text-xl mb-4">{trackData?.name}</h2>

          <p>Genre</p>

          <Select
            name="tags"
            options={genreTags}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={async (genreTag) => {
              if (genreTag?.value) {
                await addTag({
                  trackId: trackData.id,
                  tag: genreTag,
                  tags: genreTags,
                  selectedTags: selectedGenreTags,
                  setSelectedTags: setSelectedGenreTags,
                  setTags: setGenreTags,
                });
              }
            }}
            value={null}
          />

          <div className="flex flex-wrap gap-2 mt-2 mb-4">
            {selectedGenreTags.map((tag) => (
              <div key={tag.value} className="flex items-center gap-1 px-2 py-1 border border-black">
                <span className="mr-1">{tag.label}</span>
                <button
                  onClick={async () => {
                    await removeTag({
                      trackId: trackData.id,
                      tag,
                      tags: genreTags,
                      selectedTags: selectedGenreTags,
                      setSelectedTags: setSelectedGenreTags,
                      setTags: setGenreTags,
                    });
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
            ))}
          </div>

          <p>Status</p>
          <Select
            name="status"
            options={statusTags}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(statusTag) => {
              if (statusTag?.value) {
                addTag({
                  trackId: trackData.id,
                  tag: statusTag,
                  tags: statusTags,
                  selectedTags: selectedStatusTags,
                  setSelectedTags: setSelectedStatusTags,
                  setTags: setStatusTags,
                });
              }
            }}
            value={null}
          />
          <div className="flex flex-wrap gap-2 mt-2 mb-4">
            {selectedStatusTags.map((tag) => (
              <div key={tag.value} className="flex items-center gap-1 px-2 py-1 border border-black">
                <span className="mr-1">{tag.label}</span>
                <button
                  onClick={async () => {
                    await removeTag({
                      trackId: trackData.id,
                      tag,
                      tags: statusTags,
                      selectedTags: selectedStatusTags,
                      setSelectedTags: setSelectedStatusTags,
                      setTags: setStatusTags,
                    });
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
            ))}
          </div>

          <Link to={`/dashboard`} className="">
            <button className="button !w-auto">Finish & Go to dashboard</button>
          </Link>
        </div>
      )}

      {false && !trackData && (
        <Dropzone
          onDrop={(acceptedFiles: File[]) => {
            acceptedFiles.forEach(async (file) => {
              const formdata = new FormData();
              formdata.append("file", file, "[PROXY]");
              formdata.append("name", file.name);
              formdata.append("color", randomHex);

              try {
                setIsUploading(true);
                const response = await apiService.trackCreate(formdata);

                showFlash("success", "Track uploaded successfully");

                setTrackData(response.data);
              } catch (error) {
                console.error(error);
                showFlash("error", "Failed to upload track");
              } finally {
                setIsUploading(false);
              }
            });
          }}
        >
          {({
            getRootProps,
            getInputProps,
          }: {
            getRootProps: () => DropzoneRootProps;
            getInputProps: () => DropzoneInputProps & React.InputHTMLAttributes<HTMLInputElement>;
          }) => (
            <section className="border-2 border-dashed text-center cursor-pointer transition-colors">
              <div {...getRootProps()} className="p-10">
                <input {...getInputProps()} />
                {isUploading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
                    <p className="ml-2">Uploading...</p>
                  </div>
                ) : (
                  <p>Drag &apos;n&apos; drop some files here, or click to select files</p>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      )}
    </div>
  );
}
