import React, { useMemo } from "react";
import { useInboundFollowRequests } from "./hooks";
import { useProfile } from "../Profile/hooks";
import { apiService, FollowRequest } from "../../api/apiService";
import { Link } from "react-router-dom";
import { useFlash } from "../../providers/FlashProvider";

type NotificationCellProps = {
  request: FollowRequest;
  refetch: () => Promise<void>;
};

function NotificationCell({ request, refetch }: NotificationCellProps) {
  const profileArgs = useMemo(() => ({ id: request.follower_id }), [request.follower_id]);
  const { profile } = useProfile(profileArgs);

  const flashMessage = useFlash();

  const handleApprove = async () => {
    await apiService.approveFollowRequest(request.id);
    flashMessage.showFlash("success", "Follow request approved");
    refetch();
  };

  const handleDeny = async () => {
    await apiService.denyFollowRequest(request.id);
    flashMessage.showFlash("success", "Follow request denied");
    refetch();
  };

  return (
    <div className="flex items-center justify-between border-b border-gray-200 pb-4">
      <div className="flex gap-4 items-center">
        <div className="w-8 h-8" style={{ backgroundColor: profile?.user.avatar_color }}></div>

        <div>
          <div>{profile?.user.username}</div>
          <Link to={`/profile/${profile?.user.username}`}>View profile</Link>
        </div>
      </div>
      <div className="flex gap-4">
        <button className="" onClick={handleApprove}>
          Approve
        </button>
        <button className="" onClick={handleDeny}>
          Deny
        </button>
      </div>
    </div>
  );
}

function Notifications() {
  const { requests, loading, error, refetch } = useInboundFollowRequests();

  return (
    <div className="">
      <h1 className="text-2xl mb-6">Notifications</h1>

      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">{error}</div>}

      {loading ? (
        <div className="text-gray-600">Loading notifications...</div>
      ) : requests?.length === 0 ? (
        <div className="text-gray-600">No notifications</div>
      ) : (
        <div className="space-y-4">
          {requests?.map((request) => <NotificationCell key={request.id} request={request} refetch={refetch} />)}
        </div>
      )}
    </div>
  );
}

export default Notifications;
