import { useState, useEffect } from "react";
import { apiService } from "../../api/apiService";

export const useGetWaveform = (id: string) => {
  const [waveform, setWaveform] = useState<number[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchWaveform = async (id: string) => {
    setLoading(true);
    try {
      const response = await apiService.trackWaveform(id);
      setWaveform(response.data.waveform);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch tracks"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWaveform(id);
  }, []);

  return { waveform, loading, error };
};

export const useLikeTrack = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const likeTrack = async (trackId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      await apiService.trackLike(trackId);
    } catch {
      setError("Error liking track");
    } finally {
      setIsLoading(false);
    }
  };

  return { likeTrack, isLoading, error };
};

export const useUnlikeTrack = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const unlikeTrack = async (trackId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      await apiService.trackUnlike(trackId);
    } catch {
      setError("Error unliking track");
    } finally {
      setIsLoading(false);
    }
  };

  return { unlikeTrack, isLoading, error };
};
