import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/UserProvider";
import { useTheme } from "../../providers/ThemeProvider";
import Search from "../../components/Search";
// const links = [
//   { to: "/upload", label: "Upload" },
//   { to: "/search", label: "Search" },
//   { to: "/notifications", label: "Notifications" },
//   { to: "/settings", label: "Settings" },
// ];

const publicLinks = [
  { to: "/login", label: "Login" },
  { to: "/signup", label: "Sign Up" },
];

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  // const [query, setQuery] = useState("");
  // const { users, error, isLoading } = useSearch(query);

  const { session, userProfile, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Reload nav if session updates
  }, [session]);

  const { isDarkMode } = useTheme();

  const handleClose = () => {
    setIsOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-white dark:bg-black border-b border-gray-200 dark:border-gray-800 fixed top-0 w-full z-50 px-5 min-h-[52px]">
      <div className="">
        <div className="flex justify-between items-center h-16 space-x-10">
          {/* logo */}
          <div className="flex items-center space-x-10 grow">
            <Link to="/dashboard" className="text-xl font-bold">
              <div className="w-4 h-4 bg-black dark:bg-white"></div>
            </Link>
            {session && <Search />}
          </div>

          {session ? (
            <div className="space-x-10 flex justify-between items-center">
              {/* notifications */}

              <Link to="/upload" className="text-black hover:opacity-90 dark:text-white">
                Upload
              </Link>

              <div className="text-black hover:opacity-90 dark:text-white">
                <Link to="/notifications">
                  <img
                    src={`/icons/${isDarkMode ? "bell-white.svg" : "bell.svg"}`}
                    alt="Notifications"
                    className="w-6 h-6"
                  />
                </Link>
              </div>

              {/* Dropdown */}
              <div className="relative inline-block align-middle" ref={dropdownRef}>
                <button
                  className={`flex items-center space-x-2 text-black dark:text-white hover:opacity-90`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className={`w-8 h-8`} style={{ backgroundColor: userProfile?.user.avatar_color }}></div>
                </button>

                {isOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-black dark:bg-black dark:text-white dark:border-white">
                    <div className="py-1" role="menu">
                      <Link
                        to={`/profile/${userProfile?.user.username}`}
                        onClick={handleClose}
                        className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                        role="menuitem"
                      >
                        Profile
                      </Link>
                      <Link
                        to="/settings"
                        onClick={handleClose}
                        className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                        role="menuitem"
                      >
                        Settings
                      </Link>
                      <button
                        onClick={() => {
                          handleClose();
                          logout();
                          navigate("/login");
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                        role="menuitem"
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="space-x-4">
              {publicLinks.map((link) => (
                <Link to={link.to} className="text-black hover:opacity-90 dark:text-white" key={link.to}>
                  {link.label}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
