import React from "react";
import { useAuth } from "../providers/UserProvider";
import { Navigate } from "react-router-dom";
import Uploader from "../components/Uploader";

export default function Upload() {
  const { userProfile } = useAuth();

  if (!userProfile) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="mx-auto w-full">
      <h1 className="text-2xl mb-3">Upload</h1>
      <Uploader />
    </div>
  );
}
