import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useProfile } from "./hooks";
import { apiService } from "../../api/apiService";
import { AxiosError } from "axios";
import { useFlash } from "../../providers/FlashProvider";
import { useAuth } from "../../providers/UserProvider";

export default function Profile() {
  const { username } = useParams<{ username: string }>();

  const profileArgs = useMemo(() => ({ username: username ?? "" }), [username]);
  const { profile, refetch } = useProfile(profileArgs);
  const flashMessage = useFlash();
  const { userProfile } = useAuth();

  const handleFollow = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!profile?.user.id) {
      throw new Error("No profile user id");
    }

    try {
      await apiService.followRequestCreate(profile?.user.id);
      flashMessage.showFlash("success", "Follow request sent");
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err.response?.data.message);
      } else {
        console.error(err);
      }
    } finally {
      refetch();
    }
  };

  const handleUnfollow = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!profile?.user.id) {
      throw new Error("No profile user id");
    }

    try {
      await apiService.removeFollower(profile?.user.id);
      flashMessage.showFlash("success", "Unfollowed");
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err.response?.data.message);
      } else {
        console.error(err);
      }
    } finally {
      refetch();
    }
  };

  return (
    <div className="">
      <div className="">
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-4 mb-4">
              <div style={{ backgroundColor: profile?.user.avatar_color }} className="w-16 h-16"></div>

              <div>
                <h1 className="text-2xl leading-relaxed">{username}</h1>

                <div className="text-gray-500 flex gap-4">
                  <span>Followers: {profile?.follower_info.follower_count}</span>
                  <span>Following: {profile?.follower_info.following_count}</span>
                </div>
              </div>
            </div>

            <div className="text-gray-500">
              <p>Bio: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>

            <div className="mt-4">
              {userProfile?.user.id === profile?.user.id ? (
                <Link to="/settings">
                  <button className="button !w-auto">Edit Profile</button>
                </Link>
              ) : (
                <>
                  {profile?.follower_info.status === "not_following" && (
                    <button className="button !w-auto" onClick={handleFollow}>
                      Follow
                    </button>
                  )}
                  {profile?.follower_info.status === "pending" && (
                    <button className="button !w-auto" disabled>
                      Request Pending
                    </button>
                  )}
                  {profile?.follower_info.status === "approved" && (
                    <button className="button !w-auto" onClick={handleUnfollow}>
                      Unfollow
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Tracks</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {/* Track cards will go here */}
                <div className="border rounded-lg p-4">
                  <h3 className="font-medium">Track Title</h3>
                  <p className="text-gray-500 text-sm">Uploaded on: Jan 1, 2024</p>
                  <div className="mt-2">
                    <button className="text-blue-500 hover:text-blue-600">Play</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
