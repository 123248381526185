import { useEffect, useState, useCallback } from "react";
import { apiService, UserProfile } from "../../api/apiService";
import { AxiosError } from "axios";

export function useProfile(input: { username: string } | { id: number }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);

  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiService.getUserProfile(input);
      setProfile(response.data);
      setError(null);
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err.response?.data.message);
      } else {
        setError("Failed to load profile");
      }
      setProfile(null);
    } finally {
      setLoading(false);
    }
  }, [input]);

  useEffect(() => {
    fetchProfile();
  }, [input, fetchProfile]);

  return { profile, loading, error, refetch: fetchProfile };
}
