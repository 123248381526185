import React from "react";
import { useParams } from "react-router-dom";
import { useTrack } from "./hooks";
import Player from "../../components/Player";
// import Player from "../components/Player";

function Track() {
  const { id } = useParams();
  const { track, isLoading, error } = useTrack(id ? parseInt(id) : undefined);

  if (!id) {
    return <div>No track id</div>;
  }

  if (isLoading || !track) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mx-auto max-w-screen-md">
      <Player track={track} />
    </div>
  );
}

export default Track;
