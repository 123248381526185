import React from "react";
import Login from "../components/Login";

export default function LoginPage() {
  return (
    <div className="container mx-auto">
      <Login />
    </div>
  );
}
