import { useState, useEffect } from "react";
import { apiService, Track } from "../../api/apiService";

export const useTracksFeed = () => {
  const [tracks, setTracks] = useState<Track[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchTracks = async () => {
    setLoading(true);
    try {
      const response = await apiService.trackFeed();
      setTracks(response.data.tracks);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch tracks"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTracks();
  }, []);

  return { tracks, loading, error, refetch: fetchTracks };
};
