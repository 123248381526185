import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Import pages
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Upload from "./pages/Upload";
// import Search from "./pages/Search";
import Notifications from "./pages/Notifications";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { Navigate } from "react-router-dom";
import { useAuth } from "./providers/UserProvider";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Nav from "./components/Nav";
import Verify from "./pages/Verify";
import CreateAccount from "./pages/CreateAccount";
import Track from "./pages/Track";

const ProtectedRoute: React.FC<{ session: boolean; children: React.ReactNode }> = ({ session, children }) => {
  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default function AppRoutes() {
  const { session } = useAuth();

  useEffect(() => {
    // reload if session changes
  }, [session]);

  return (
    <div className="min-h-screen relative">
      <Nav />
      <div className="box-border flex flex-col flex-grow w-full mx-auto min-h-[calc(100vh-52px)] relative top-[52px] p-4">
        <Routes>
          <Route path="/dashboard" element={<ProtectedRoute session={session}>{<Dashboard />}</ProtectedRoute>} />
          <Route path="/upload" element={<ProtectedRoute session={session}>{<Upload />}</ProtectedRoute>} />
          {/* <Route path="/search" element={<ProtectedRoute session={session}>{<Search />}</ProtectedRoute>} /> */}
          <Route
            path="/notifications"
            element={<ProtectedRoute session={session}>{<Notifications />}</ProtectedRoute>}
          />
          <Route path="/track/:id" element={<ProtectedRoute session={session}>{<Track />}</ProtectedRoute>} />
          <Route path="/profile/:username" element={<ProtectedRoute session={session}>{<Profile />}</ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute session={session}>{<Settings />}</ProtectedRoute>} />

          <Route path="/" element={session ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </div>
    </div>
  );
}
