import React, { useState, useEffect, useRef } from "react";
import { Howl } from "howler";
import { Track } from "../../api/apiService";
import { useAudio } from "../../providers/AudioProvider";
import { useAuth } from "../../providers/UserProvider";
import { useTheme } from "../../providers/ThemeProvider";
import { useGetWaveform, useLikeTrack, useUnlikeTrack } from "./hooks";
import { Link } from "react-router-dom";

interface PlayerProps {
  track?: Track;
  onEnded?: () => void;
}

const Player: React.FC<PlayerProps> = ({ track, onEnded }) => {
  const { userProfile } = useAuth();

  // TODO: remove after testing. uncomment the following line to generate waveform from server.
  // const { waveform: waveformData } = useGetWaveform(track == undefined ? "" : track.id);
  const waveformData = track?.waveform;

  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [liked, setLiked] = useState<boolean>(!!track?.likes_track);
  const [likeCount, setLikeCount] = useState<number>(track?.like_count || 0);

  const { likeTrack, isLoading: likeLoading, error: likeError } = useLikeTrack();
  const { unlikeTrack, isLoading: unlikeLoading, error: unlikeError } = useUnlikeTrack();

  const howlerRef = useRef<Howl | null>(null);
  const progressInterval = useRef<NodeJS.Timeout>();
  const currentlyPlayingRef = useRef<Howl | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { isDarkMode } = useTheme();

  const { setCurrentPlayer, currentPlayer } = useAudio();

  const canvasLinearGradient = (canvasCtx: CanvasRenderingContext2D, colorStops: Array<[number, string, number?]>) => {
    const height = canvasCtx.canvas.height;

    const gradient = canvasCtx.createLinearGradient(0, 0, 0, height);

    colorStops.forEach(([stop, color, opacity = 1]) => {
      gradient.addColorStop(stop, `rgba(${color},${opacity})`);
    });

    return gradient;
  };

  const memoizeScale = (max: number, data: number[]) => {
    const memo: number[] = [];
    return (v: number) => {
      const s = data[v];
      if (!memo[s]) {
        memo[s] = (max - data[v]) / max;
      }
      return memo[s];
    };
  };

  const gradientStyles = [
    `linear-gradient(to right, ${userProfile?.user.avatar_color}, ${track?.color || "#000"})`,
    // `radial-gradient(circle, ${track?.color}, ${userProfile?.user.avatar_color || "#000"})`,
    // `linear-gradient(to bottom, ${userProfile?.user.avatar_color}, ${track?.color || "#000"})`,
    // `linear-gradient(45deg, ${userProfile?.user.avatar_color}, ${track?.color || "#000"})`,
    // `radial-gradient(circle, ${userProfile?.user.avatar_color}, ${track?.color || "#000"})`,
    // `conic-gradient(${userProfile?.user.avatar_color}, ${track?.color || "#000"})`,
    // `linear-gradient(to bottom, rgba(255,255,255,0.3), rgba(0,0,0,0.3)), radial-gradient(circle, ${track?.color || "#000"} 50%, ${userProfile?.user.avatar_color})`,
    // `radial-gradient(circle, ${track?.color || "#000"} 50%, rgba(255,255,255,0) 70%), linear-gradient(to right, ${userProfile?.user.avatar_color}, rgba(0,0,0,0))`,
  ];

  const getRandomGradientStyle = () => {
    const randomIndex = Math.floor(Math.random() * gradientStyles.length);
    return {
      background: gradientStyles[randomIndex],
    };
  };

  const combinedColorStyle = getRandomGradientStyle();

  useEffect(() => {
    if (track) {
      // Cleanup previous howler instance
      if (howlerRef.current) {
        howlerRef.current.unload();
      }

      // Create new howler instance
      howlerRef.current = new Howl({
        src: [track.url],
        html5: true,
        onend: () => {
          setPlaying(false);
          setProgress(0);
          currentlyPlayingRef.current = null;
          setCurrentPlayer(null);
          if (onEnded) onEnded();
        },
      });
    }

    return () => {
      if (howlerRef.current) {
        howlerRef.current.unload();
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [track, onEnded, setCurrentPlayer]);

  useEffect(() => {
    if (!canvasRef.current || !waveformData) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const config = {
      maxVal: 1,
      linePercent: 0.7,
      barWidth: 3,
      gapWidth: 3,
    };

    // Draw base waveform
    const width = canvas.width;
    const height = canvas.height;
    const scaledSamples = memoizeScale(config.maxVal, waveformData);
    const g = config.linePercent * height;
    const v = height - g;
    const increment = config.barWidth + config.gapWidth;
    let w = 0,
      x = 0;

    ctx.clearRect(0, 0, width, height);
    ctx.save();
    ctx.beginPath();

    for (let k = 0; k < width; k += increment) {
      const C = scaledSamples(((k / width) * waveformData.length) | 0);
      const T = (C * g) | 0;
      const S = ((1 - C) * v + g) | 0;

      // Draw bar
      ctx.rect(k, T, config.barWidth, S - T);

      // Draw gap
      const A = Math.max(T, w);
      ctx.fillStyle = "transparent";
      ctx.fillRect(k - config.gapWidth, A, config.gapWidth, Math.min(S, x) - A);

      w = T;
      x = S;
    }

    ctx.fillStyle = canvasLinearGradient(ctx, [
      [0, !isDarkMode ? "50,50,50" : "255,255,255"],
      [0.7, !isDarkMode ? "50,50,50" : "255,255,255"],
      [0.701, !isDarkMode ? "30,30,30" : "229,229,229"],
      [1, !isDarkMode ? "30,30,30" : "229,229,229"],
    ]);
    ctx.fill();
    ctx.clearRect(0, g, width, 1);

    // Draw progress overlay
    ctx.fillStyle = "#3B82F6"; // blue-500
    ctx.globalCompositeOperation = "source-atop";
    ctx.fillRect(0, 0, width * (progress / 100), height);

    ctx.restore();
  }, [waveformData, progress, isDarkMode]);

  const togglePlayPause = () => {
    if (!howlerRef.current) return;

    if (playing) {
      howlerRef.current.pause();
      currentlyPlayingRef.current = null;
      setCurrentPlayer(null);
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
      setPlaying(false);
    } else {
      // Stop any currently playing instance using the context
      if (currentPlayer && currentPlayer !== howlerRef.current) {
        currentPlayer.pause();
        currentPlayer.seek(0); // Reset to beginning
        setCurrentPlayer(null);
      }

      howlerRef.current.play();
      setCurrentPlayer(howlerRef.current);
      currentlyPlayingRef.current = howlerRef.current;
      progressInterval.current = setInterval(() => {
        if (howlerRef.current) {
          const seek = howlerRef.current.seek() as number;
          const duration = howlerRef.current.duration();
          const p = (seek / duration) * 100;
          setProgress(p ? p : 0);
        }
      }, 100);
      setPlaying(true);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!howlerRef.current) return;

    const newPosition = parseFloat(e.target.value);
    const duration = howlerRef.current.duration();
    const seekTime = (duration * newPosition) / 100;

    howlerRef.current.seek(seekTime);
    setProgress(newPosition);
  };

  const renderWaveform = () => {
    if (!waveformData) return null;

    return (
      <div className="relative w-full h-20 max-w-4xl">
        <canvas
          ref={canvasRef}
          width={800 * window.devicePixelRatio}
          height={100 * window.devicePixelRatio}
          className="top-0 left-0 w-full h-full mx-auto  max-w-4xl"
        />
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleSeek}
          className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
        />
      </div>
    );
  };

  const handleLikeToggle = async () => {
    if (!track) return;

    const newLikedState = !liked;
    setLiked(newLikedState);
    setLikeCount((prev) => (newLikedState ? prev + 1 : prev - 1));

    try {
      if (newLikedState) {
        await likeTrack(track.id);
      } else {
        await unlikeTrack(track.id);
      }
    } catch (error) {
      console.error("Failed to update like state", error);
      setLiked(!newLikedState);
      setLikeCount((prev) => (newLikedState ? prev - 1 : prev + 1));
    }
  };

  useEffect(() => {
    if (track) {
      setLiked(!!track.likes_track);
      setLikeCount(track.like_count || 0);
    }
  }, [track]);

  useEffect(() => {
    // rerender
  }, [playing]);

  useEffect(() => {
    if (currentPlayer !== howlerRef.current && playing) {
      // Reset this player's state if it's not the current player
      setPlaying(false);
      setProgress(0);
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
      if (howlerRef.current) {
        howlerRef.current.seek(0); // Reset to beginning
      }
    }
  }, [currentPlayer, playing]);

  return (
    <div className="">
      <div className="flex flex-col space-y-4">
        {/* Avatar and Track Details */}
        <div className="flex space-x-4">
          <div className="w-12 h-12 flex-shrink-0" style={combinedColorStyle} />
          <div className="flex-1 min-w-0">
            <Link to={`/profile/${userProfile?.user.username}`}>
              <p className="text-sm truncate">{userProfile?.user.username}</p>
            </Link>
            <Link to={`/track/${track?.id}`}>
              <p className="text-lg font-light truncate">{track?.name}</p>
            </Link>
          </div>
        </div>
        {/* Play Button and Waveform */}
        <div className="flex items-center space-x-4">
          <button
            onClick={togglePlayPause}
            className="w-12 h-12 flex items-center justify-center border border-black dark:border-white"
          >
            {playing ? (
              // Pause Icon
              <div className="flex space-x-1">
                <div className={`w-1.5 h-5 ${isDarkMode ? "bg-white" : "bg-black"}`}></div>
                <div className={`w-1.5 h-5 ${isDarkMode ? "bg-white" : "bg-black"}`}></div>
              </div>
            ) : (
              // Play Icon
              <svg
                className="w-6 h-6"
                fill={isDarkMode ? "white" : "black"}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="4,2 16,10 4,18" />
              </svg>
            )}
          </button>
          <div className="flex-1">{renderWaveform()}</div>
        </div>
        {/* Like Button and Count */}
        <div className="flex items-center space-x-2">
          <button
            onClick={handleLikeToggle}
            className="flex items-center space-x-1 text-sm focus:outline-none"
            disabled={likeLoading || unlikeLoading}
          >
            <span className="text-2xl">{liked ? "❤️" : "🤍"}</span>
            <span className="text-gray-600 dark:text-gray-300">{likeCount}</span>
          </button>
        </div>
        {/* Tags */}
        <div className="flex space-x-2 mt-2 ml-[4rem]">
          {track?.track_tags.map((tag) => (
            <div
              key={tag.id}
              className="px-2 py-1 text-sm bg-white border border-black dark:bg-black dark:text-white dark:border-white"
            >
              {tag.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Player;
