import apiClient from "./client";

export type User = {
  id: number;
  username: string;
  email: string;
  avatar_color: string;
};

type AuthResponse = {
  access_token: string;
  refresh_token: string;
  user: User;
};

export type Track = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  url: string;
  user_id: number;
  waveform: number[];
  likes_track: boolean;
  like_count: number;
  color: string;
  duration_seconds: number;
  track_tags: Tag[];
};

export type FollowRequest = {
  id: number;
  follower_id: number;
  followee_id: number;
  status: string;
  created_at: string;
  updated_at: string;
};

export enum FollowStatus {
  NOT_FOLLOWING = "not_following",
  PENDING = "pending",
  APPROVED = "approved",
}

export type UserProfile = {
  user: User;
  follower_info: {
    follower_count: number;
    following_count: number;
    status: FollowStatus;
  };
};

export type Tag = {
  id: number;
  name: string;
  category: "genre" | "status";
};

export const apiService = {
  login: (email: string, password: string) => apiClient.post<AuthResponse>("/auth/login", { email, password }),
  signup: (email: string) => apiClient.post("/auth/signup", { email }),
  refreshAccessToken: (refresh_token: string) => apiClient.post<AuthResponse>("/auth/token/refresh", { refresh_token }),
  verifyAccount: (email_verification_token: string, username: string, password: string, avatar_color: string) =>
    apiClient.post<AuthResponse>("/auth/verify", { email_verification_token, username, password, avatar_color }),

  getAuthenticatedUser: () => apiClient.get<UserProfile>("/user/me"),
  getUserProfile: (input: { username: string } | { id: number }) =>
    apiClient.get<UserProfile>(
      `/user/profile?${"username" in input ? `username=${input.username}` : `id=${input.id}`}`,
    ),
  userSearch: (query: string) => apiClient.get<User[]>(`/user/search?q=${query}`),

  updateUserAvatarColor: (avatar_color: string) => apiClient.post<User>("/user/avatar", { avatar_color }),
  updateUserEmail: (email: string) => apiClient.post<User>("/user/email", { email }),
  updateUserPassword: (password: string) => apiClient.post<User>("/user/password", { password }),
  updateUserUsername: (username: string) => apiClient.post<User>("/user/username", { username }),
  // updateUserNotificationPreferences: (preferences: any) => apiClient.post("/user/notificationPreferences/update", { preferences }),

  followRequestCreate: (user_id: number) => apiClient.post(`/follow/${user_id}`),
  followStatus: (user_id: number) => apiClient.get<{ status: FollowStatus }>(`/follow/${user_id}`),
  removeFollower: (user_id: number) => apiClient.delete(`/follow/${user_id}`),
  followRequestsInbound: () => apiClient.get<{ requests: FollowRequest[] }>("/follow-requests/inbound"),
  followRequestsOutbound: () => apiClient.get<{ requests: FollowRequest[] }>("/follow-requests/outbound"),
  approveFollowRequest: (request_id: number) => apiClient.post(`/follow-requests/${request_id}/approve`),
  denyFollowRequest: (request_id: number) => apiClient.post(`/follow-requests/${request_id}/deny`),

  requestResetPassword: (email: string) => apiClient.post("/auth/password/reset/request", { email }),
  resetPassword: (token: string, new_password: string) =>
    apiClient.post("/auth/password/reset", { token, new_password }),

  trackCreate: (formData: FormData) =>
    apiClient.post<{ id: number; name: string }>("/track", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  trackFeed: () => apiClient.get<{ tracks: Track[] }>("/tracks/feed"),
  trackFetchFollowing: () => apiClient.get<{ tracks: Track[] }>("/tracks/following"),
  trackFetchMine: () => apiClient.get<{ tracks: Track[] }>("/tracks/mine"),
  trackDelete: (track_id: string) => apiClient.delete(`/track/${track_id}`),
  trackGet: (track_id: number) => apiClient.get<Track>(`/track/${track_id}`),
  trackLike: (track_id: string) => apiClient.post(`/track/${track_id}/like`),
  trackUnlike: (track_id: string) => apiClient.delete(`/track/${track_id}/like`),
  trackWaveform: (track_id: string) => apiClient.get<{ waveform: number[] }>(`/track/${track_id}/waveform`),
  tags: () => apiClient.get<{ tags: Tag[] }>(`/tags`),
  trackAddTag: (track_id: number, tag_id: number) => apiClient.post(`/track/${track_id}/tag/${tag_id}`),
  trackRemoveTag: (track_id: number, tag_id: number) => apiClient.delete(`/track/${track_id}/tag/${tag_id}`),
};
