import { useEffect, useState } from "react";
import { Track, apiService } from "../../api/apiService";

export const useTrack = (trackId: number | undefined) => {
  const [track, setTrack] = useState<Track | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrack = async () => {
      try {
        setIsLoading(true);
        if (!trackId) {
          setError("No track id");
          setTrack(null);
          setIsLoading(false);
          return;
        }

        const trackData = await apiService.trackGet(trackId);
        setTrack(trackData.data);
        setError(null);
      } catch {
        setError("Failed to load track");
        setTrack(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (trackId) {
      fetchTrack();
    }
  }, [trackId]);

  return { track, isLoading, error };
};
