import { AxiosError } from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/UserProvider";
import { apiService } from "../api/apiService";

export default function CreateAccountPage() {
  const navigate = useNavigate();
  const { verifyToken, setAuthTokens, setUserProfile } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    // TODO: extract into a util because it's used by upload.
    avatarColor: `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      setLoading(true);

      if (!verifyToken) {
        navigate("/verify");
        return;
      }

      const {
        data: { access_token, refresh_token },
      } = await apiService.verifyAccount(verifyToken, formData.username, formData.password, formData.avatarColor);

      setAuthTokens(access_token, refresh_token);

      const { data: userProfile } = await apiService.getAuthenticatedUser();
      setUserProfile(userProfile);

      navigate("/dashboard");
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err.response?.data?.message || "Failed to create account");
      } else {
        setError("Failed to create account");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-light">Create Account</h2>
          <p className="mt-2 text-center text-gray-600 dark:text-gray-400">
            Please fill in your details to create an account
          </p>
        </div>

        {error && (
          <div className="bg-red-50 p-4">
            <div className="text-sm text-red-700">{error}</div>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="username" className="text-black dark:text-white block mb-2 font-base">
                Username
              </label>
              <input
                name="username"
                type="text"
                required
                className="input"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="password" className="text-black dark:text-white block mb-2 font-base">
                Password
              </label>
              <input
                name="password"
                type="password"
                required
                className="input"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>

            <div>
              <label htmlFor="avatarColor" className="text-black dark:text-white block mb-2 font-base">
                Avatar Color
              </label>
              <input
                name="avatarColor"
                type="color"
                required
                className="input h-10"
                value={formData.avatarColor}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <button type="submit" disabled={loading} className="button">
              {loading ? "Creating Account..." : "Create Account"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
