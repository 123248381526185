import { useCallback, useEffect, useState } from "react";
import { apiService, Tag } from "../../api/apiService";

export const useTags = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchTags = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.tags();

      const { tags } = response.data;

      setTags(tags);
    } catch (err) {
      setError("Failed to fetch tags");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  return {
    tags,
    isLoading,
    error,
  };
};
