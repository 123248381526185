import { createContext } from "react";
import type { UserProfile } from "../api/apiService";

export interface UserContextType {
  userProfile: UserProfile | null;
  setUserProfile: (userProfile: UserProfile) => void;
  session: boolean;
  setAuthTokens: (token: string, refreshToken: string) => void;
  loading: boolean;
  logout: () => void;
  verifyToken: string | null;
  setVerifyToken: (token: string) => void;
  checkAuth: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export default UserContext;
